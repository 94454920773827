import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Card } from 'hds-react';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/card/customisation",
  "title": "Card - Customisation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`In Core version, you can either use the `}<inlineCode parentName="p">{`style`}</inlineCode>{` or `}<inlineCode parentName="p">{`class`}</inlineCode>{` attributes in the HTML to customise the component.`}</p>
    <p>{`In React version, you can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component.`}</p>
    <p>{`See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--background-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background colour of the card.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the card's border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--border-width`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Width of the card's border`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--color`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Colour of the built-in heading and text.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--padding-horizontal`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Left and right padding of the card.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--padding-vertical`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Top and bottom padding of the card.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Card
  border
  heading="Customised card"
  text="In order to customise the card component, use the theme property."
  theme={{
    '--background-color': 'var(--color-black-5)',
    '--border-color': 'var(--color-black-90)',
    '--border-width': '3px',
    '--color': 'var(--color-black-90)',
    '--padding-horizontal': 'var(--spacing-l)',
    '--padding-vertical': 'var(--spacing-m)'
  }}
/>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div
  class="hds-card hds-card--border"
  role="region"
  style="--background-color:var(--color-black-5); --border-color:var(--color-black-90); --border-width:3px;
  --color:var(--color-black-90); --padding-horizontal:var(--spacing-l); --padding-vertical:var(--spacing-m);"
>
  <div class="hds-card__body">
    <div class="heading-m" role="heading" aria-level="2">Customised card</div>
    <div class="hds-card__text">In order to customise the card component, use the theme properties.</div>
  </div>
</div>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      